<template>
  <span :class="{ urgent: isUrgent }">{{ formattedTimeLeft }}</span>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from "vue";

const props = defineProps<{
  endDateTime: string;
}>();

const timeLeft = ref("");
const isUrgent = ref(false);

const updateTimeLeft = () => {
  const now = new Date();
  const end = new Date(props.endDateTime);
  const diff = end.getTime() - now.getTime();

  if (diff <= 0) {
    timeLeft.value = "Ended";
    isUrgent.value = false;
    return;
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  timeLeft.value = `${days}d ${hours}h ${minutes}m ${seconds}s`;
  isUrgent.value = diff < 5 * 60 * 1000; // Less than 5 minutes
};

let interval: NodeJS.Timeout;

onMounted(() => {
  updateTimeLeft();
  interval = setInterval(updateTimeLeft, 1000);
});

onUnmounted(() => {
  clearInterval(interval);
});

const formattedTimeLeft = computed(() => timeLeft.value);
</script>

<style scoped>
.urgent {
  font-weight: bold;
  color: red;
}
</style>
